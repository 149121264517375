import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

const NAV_BAR_HEIGHT = 64;
const PADDING = 16;

/**
 * Detects hashes in the URL and scrolls to the element with the corresponding id
 */
const useSmoothScrollToHash = () => {
  const location = useLocation();
  const history = useHistory();

  function scrollTo(elementId: string) {
    history.push(`#${elementId}`);
  }

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.replace("#", ""));

      setTimeout(() => {
        window.scrollTo({
          behavior: element ? "smooth" : "auto",
          top: element ? element.offsetTop - NAV_BAR_HEIGHT - PADDING : 0,
        });
      }, 20);
    }
  }, [location]);

  return scrollTo;
};

export default useSmoothScrollToHash;
