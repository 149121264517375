import create, { State } from "zustand";

interface MapState extends State {
  selectedProject?: string;
  clearSelectedProject: () => void;
  handleSelectProject: (projectId: string) => void;
}

// State selectors to avoid creating a new function on each render
export const selectedProjectSelector = (state: MapState) => state.selectedProject;
export const clearSelectedProjectSelector = (state: MapState) => state.clearSelectedProject;
export const handleSelectProjectSelector = (state: MapState) => state.handleSelectProject;

/**
 * Store selected project to avoid losing the selection when switching between tabs
 */
const useProjectMapStore = create<MapState>((set, get) => ({
  selectedProject: undefined,
  clearSelectedProject: () => set({ selectedProject: undefined }),
  handleSelectProject: (projectId) => set({ selectedProject: projectId }),
}));

export default useProjectMapStore;
