import React from "react";
import { Close32 } from "@carbon/icons-react";
import { Grid, Box, Typography, IconButton, Slide } from "@material-ui/core";
import styled from "styled-components";

import { palette, spacing } from "../theme";
import { Project, ImpactOverview } from "../types";
import { DateFormatter } from "../utils";
import getProjectTypeDetails from "../utils/get-project-type";
import background from "../assets/images/blur-background.jpg";
import ExternalLinkButton from "./ExternalLinkButton";
import Card from "./Card";
import IconCard from "./IconCard";

interface Props {
  project: Project;
  visible: boolean;
  onClose: () => void;
  impact?: ImpactOverview;
}

const Container = styled(Box)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: ${palette("primary.main")};
  background-image: url(${background});
  background-position: left top;
  background-size: cover;

  padding: ${spacing(2)}px;
  color: white;
  overflow-y: auto;
`;

const SecondaryTypography = styled(Typography)`
  opacity: 0.8;
`;

interface StatisticProps {
  label: string;
  children: React.ReactNode;
}

const Statistic = ({ label, children }: StatisticProps) => (
  <>
    <Grid item xs={4}>
      <SecondaryTypography variant="body2">{label}</SecondaryTypography>
    </Grid>
    <Grid item xs={8}>
      {children}
    </Grid>
  </>
);

const ProjectDrawer = ({ visible, project, onClose, impact }: Props) => {
  const projectType = getProjectTypeDetails(project.type);

  return (
    <Slide in={visible} direction="left">
      <Container width={["100%", "100%", "50%"]}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between" alignItems="flex-start">
              <Typography variant="h4">{project.name}</Typography>
              <IconButton onClick={onClose}>
                <Close32 color="white" />
              </IconButton>
            </Box>
          </Grid>

          {impact && (
            <>
              <Grid item xs={12}>
                <SecondaryTypography variant="body2">Your collaboration with {project.name}</SecondaryTypography>
              </Grid>
              <Grid item xs={6}>
                <Card glass>
                  <Typography variant="body2">Carbon captured</Typography>
                  <Typography variant="h5" component="p">
                    {impact.carbonCaptured.fromGrams().toString({ equivalent: false })}
                  </Typography>
                </Card>
              </Grid>

              <Grid item xs={6}>
                <Card glass>
                  <Typography variant="body2">Carbon credits retired</Typography>
                  <Typography variant="h5" component="p">
                    {impact.creditsPurchased.toLocaleString()}
                  </Typography>
                </Card>
              </Grid>
            </>
          )}

          <Statistic label="Joined our portfolio">
            <Typography variant="body2">
              {new DateFormatter(project.date_joined).toString({ dateStyle: "short" })}
            </Typography>
          </Statistic>

          <Statistic label="Project type">
            <IconCard icon={projectType.icon} display="inline-flex" bgcolor={projectType.color}>
              <Typography variant="body2">{projectType.title}</Typography>
            </IconCard>
          </Statistic>

          <Statistic label="Highlights">
            <Typography variant="body2">{project.tags.join(", ")}</Typography>
          </Statistic>

          <Grid item xs={12}>
            <Typography variant="body2">{project.description}</Typography>
          </Grid>

          <Grid item xs={12}>
            <ExternalLinkButton href={project.url} variant="text" size="small" fullWidth={false}>
              Read more
            </ExternalLinkButton>
          </Grid>
        </Grid>
      </Container>
    </Slide>
  );
};

export default ProjectDrawer;
