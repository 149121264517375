import React from "react";
import { Box, Fade, Typography } from "@material-ui/core";
import Share from "@material-ui/icons/Share";
import { Close32, Help24 } from "@carbon/icons-react";
import styled from "styled-components";
import { Button, CompensateEmailLink, palette, spacing } from "@carbon/shared";
import { motion, AnimateSharedLayout } from "framer-motion";

import { ReactComponent as CompensateLogo } from "../assets/compensate-condensed.svg";
import background from "../assets/background.jpg";
import DateRangePicker from "../components/DatePicker";

interface Props {
  companyName?: string;
  companyLogo?: string; // Image URL
  onOpenHelpModal: () => void;
  reportNotFound?: boolean;
  hideLogoBackground?: boolean;
}

const TRANSITION = {
  type: "tween",
  ease: "circOut",
  duration: 0.5,
};

const HIDDEN_STYLES = { opacity: 0, y: 100 };

const SHOWN_STYLES = { opacity: 1, y: 0 };

const ANIMATION_VARIANTS = {
  container: {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.25,
      },
    },
  },

  child: {
    hidden: HIDDEN_STYLES,
    show: { ...SHOWN_STYLES, transition: TRANSITION },
  },

  /**
   * Transition used for children rendered after loading something
   * transition is excluded to allow a custom transitions with staggered delays
   */
  deferredChild: {
    hidden: HIDDEN_STYLES,
    show: SHOWN_STYLES,
  },
};

const getDelayedTransition = (index: number) => ({
  ...TRANSITION,
  delay: index * 0.2,
});

const HeroBackground = styled(Box)`
  background-position: center top;
  position: relative;
  color: ${palette("text.light")};
  background-color: ${palette("primary.dark")};
  background-size: cover;
  background-image: url(${background});
  min-height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Header = styled.header`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: ${spacing(2)}px;

  button {
    margin-left: ${spacing(2)}px;
  }
`;

const MotionWrapper = styled(motion.div)`
  max-width: 1400px;
  padding: ${spacing(16, 4)};
  text-align: center;
  color: ${palette("text.light")};
  overflow-x: hidden;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-left: ${spacing(1)}px;
    padding-right: ${spacing(1)}px;
  }
`;

const StyledCompensateLogo = styled(CompensateLogo)`
  width: 140px;
  height: auto;

  ${({ theme }) => theme.breakpoints.down("xs")} {
    width: 120px;
  }
`;

const Logo = styled.div<{ $hideLogoBackground?: boolean }>`
  width: 160px;
  height: 160px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${spacing(1)}px;

  img {
    max-width: 100%;
  }

  ${({ $hideLogoBackground }) =>
    !$hideLogoBackground &&
    `
    background: #fff;
    border-radius: 50%;
  `}

  ${({ theme }) => theme.breakpoints.down("xs")} {
    width: 120px;
    height: 120px;
  }
`;

function getShareData(companyName?: string): ShareData {
  const url = window.location.href;

  if (companyName) {
    return {
      url,
      text: `Check out the ${companyName} Compensation Impact Report in collaboration with Compensate.`,
    };
  }

  return {
    url,
    text: "Check out our Compensation Impact Report in collaboration with Compensate.",
  };
}

const Hero = ({ companyName, companyLogo, onOpenHelpModal, reportNotFound, hideLogoBackground }: Props) => {
  const shareData = getShareData(companyName);
  const canShare = !!window.navigator?.canShare?.(shareData) && !reportNotFound;

  async function handleShare() {
    if (canShare) {
      try {
        await navigator.share(shareData);
      } catch (error) {
        // Do nothing
      }
    }
  }

  return (
    <HeroBackground bgcolor="black">
      <Fade in={true}>
        <Header>
          <Button size="small" variant="text" color="light" endIcon={<Help24 />} onClick={onOpenHelpModal}>
            What's this
          </Button>
          {canShare && (
            <Button size="small" endIcon={<Share />} onClick={handleShare}>
              Share report
            </Button>
          )}
        </Header>
      </Fade>

      <AnimateSharedLayout>
        <MotionWrapper variants={ANIMATION_VARIANTS.container} initial="hidden" animate="show">
          {!reportNotFound && (
            <motion.div layout key="title" variants={ANIMATION_VARIANTS.child}>
              <Typography variant="h2" component="h1">
                Compensation Impact Report
              </Typography>
            </motion.div>
          )}

          <Box my={6} display="flex" alignItems="center" justifyContent="center" height={200}>
            <motion.div layout key="compensate-logo" variants={ANIMATION_VARIANTS.child} style={{ fontSize: 0 }}>
              <StyledCompensateLogo />
            </motion.div>

            {companyLogo && (
              <>
                <motion.div key="icon" variants={ANIMATION_VARIANTS.deferredChild} transition={getDelayedTransition(1)}>
                  <Box mx={[1, 3, 6]}>
                    <Close32 />
                  </Box>
                </motion.div>
                <motion.div key="logo" variants={ANIMATION_VARIANTS.deferredChild} transition={getDelayedTransition(2)}>
                  <Logo $hideLogoBackground={hideLogoBackground}>
                    <img alt={companyName} src={companyLogo} />
                  </Logo>
                </motion.div>
              </>
            )}
          </Box>

          {!reportNotFound && companyName && (
            <motion.div key="name" variants={ANIMATION_VARIANTS.deferredChild} transition={getDelayedTransition(3)}>
              <Typography variant="h2" gutterBottom>
                {companyName}
              </Typography>
              <DateRangePicker />
            </motion.div>
          )}

          {reportNotFound && (
            <motion.div key="name" variants={ANIMATION_VARIANTS.deferredChild} transition={getDelayedTransition(3)}>
              <Typography variant="h3" component="h1" paragraph>
                Oops, we couldn't find your report
              </Typography>
              <Typography variant="subtitle2">
                Please check that the link to your Compensation Impact Report is correct.
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                If the problem persists or you've lost your link, contact us at <CompensateEmailLink />
              </Typography>
            </motion.div>
          )}
        </MotionWrapper>
      </AnimateSharedLayout>
    </HeroBackground>
  );
};

export default Hero;
