import React, { useEffect } from "react";
import styled from "styled-components";
import { MenuItem, TextField } from "@material-ui/core";
import {
  palette,
  spacing,
  useDateRangePicker,
  handleSelectDatePresetSelector,
  DatePresetOption,
  selectedDatePresetSelector,
  YEAR_PRESET_RANGES,
  configurePresetsSelector,
} from "@carbon/shared";

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    justify-self: center;
    color: ${palette("text.light")};
    font-size: 24px;
    opacity: 0.8;
  }

  .MuiSelect-icon {
    color: ${palette("text.light")};
  }

  .MuiSelect-selectMenu {
    padding-left: ${spacing(2)}px;
    padding-top: ${spacing(1)}px;
    padding-bottom: ${spacing(1)}px;
  }
`;

function DateRangePicker() {
  const configurePresets = useDateRangePicker(configurePresetsSelector);
  const selectedPreset = useDateRangePicker(selectedDatePresetSelector);
  const handleSelectPreset = useDateRangePicker(handleSelectDatePresetSelector);

  useEffect(() => {
    configurePresets(YEAR_PRESET_RANGES);
  }, [configurePresets]);

  return (
    <StyledTextField
      variant="standard"
      fullWidth={false}
      id="select-date-range"
      select
      aria-label="select date range"
      value={selectedPreset}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSelectPreset(e.target.value as DatePresetOption)}
      InputLabelProps={{ shrink: false }}
      InputProps={{ disableUnderline: true }}
      SelectProps={{
        MenuProps: {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        },
      }}
    >
      {YEAR_PRESET_RANGES.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </StyledTextField>
  );
}

export default DateRangePicker;
