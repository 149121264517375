import React from "react";
import { Router } from "react-router-dom";
import { ScrollToTop, useInjectScript } from "@carbon/shared";

import { history } from "./utils/history";
import { GTM_SCRIPT_PROD, GTM_SCRIPT_DEV } from "./constants/analytics";
import config from "./config";
import ImpactPage from "./pages/Impact";

const environment = config.get("env");
const isAnalyticsEnabled = config.get("analytics");

function App() {
  useInjectScript(environment === "production" ? GTM_SCRIPT_PROD : GTM_SCRIPT_DEV, isAnalyticsEnabled);

  return (
    <Router history={history}>
      <ScrollToTop />

      <ImpactPage />
    </Router>
  );
}

export default App;
