import React from "react";
import { Dialog, Typography, IconButton, DialogContent, Grid, Link } from "@material-ui/core";
import { Close32 } from "@carbon/icons-react";
import {
  Accordion,
  CompensateIcon,
  CompensateLogo,
  getStaticAsset,
  palette,
  spacing,
  Button,
  COMPENSATE_COM_BUSINESS_LINK,
  MOBY_GOOGLE_PLAY,
  MOBY_APP_STORE,
} from "@carbon/shared";
import styled from "styled-components";

interface Props {
  customerName?: string;
  isOpen: boolean;
  onClose: () => void;
}

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    overflow: hidden;
    border-radius: ${spacing(6)}px;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  padding: ${spacing(4)}px;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding: ${spacing(8)}px;
  }
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: ${spacing(1)}px;
  right: ${spacing(1)}px;
  width: 72px;
  height: 72px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const DialogHero = styled.div`
  position: relative;
  padding: ${spacing(4, 8)};
  background-color: ${palette("primary.dark")};
  background-image: url(${getStaticAsset("/images/pexels-tarcila-mesquita-flower-hero.jpg")});
  background-position: center center;
  background-size: cover;
  color: ${palette("text.light")};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: ${spacing(4)}px;
  }
`;

const Highlight = styled.span`
  color: ${palette("primary.main")};
`;

const AppLink = styled.a`
  margin-right: ${spacing(1)}px;

  img {
    display: inline-block;
    width: 150px;
  }
`;

const WhatElseCanIDo = () => (
  <Grid container spacing={6} justifyContent="center">
    <Grid item sm={6} lg={5} xl={4}>
      <CompensateIcon icon="package" />
      <Typography variant="h5" paragraph>
        Compensate for businesses
      </Typography>
      <Typography paragraph>
        We offer easy access to the highest quality carbon projects to all types of businesses. Our all-inclusive
        services cover emission calculations and reporting, carbon offsetting, and communications support. We also have
        an API solution available for e-commerce solutions and applications. Act for the climate today!
      </Typography>
      <Button
        component={Link}
        withArrow
        variant="outlined"
        color="dark"
        size="large"
        target="_blank"
        rel="noreferrer"
        href={COMPENSATE_COM_BUSINESS_LINK}
      >
        See our business solutions
      </Button>
    </Grid>
    <Grid item sm={6} lg={5} xl={4}>
      <CompensateIcon icon="house" />
      <Typography variant="h5" paragraph>
        Compensate for your personal emissions
      </Typography>
      <Typography paragraph>
        With the Compensate app, you can understand your carbon footprint, take action to reduce your CO₂ emissions and
        compensate for the rest. Update your footprint and compensation subscription at any time, and see how your
        personal impact is adding up.
      </Typography>
      <AppLink href={MOBY_GOOGLE_PLAY} target="_blank" rel="noreferrer">
        <img
          src="https://images.ctfassets.net/f6kng81cu8b8/7MXypSL3fDveheWtQSAR4R/858b04502f2ce955271b07a7000d527e/googlePlay.svg"
          alt="Compensate on Google Play"
        />
      </AppLink>

      <AppLink href={MOBY_APP_STORE} target="_blank" rel="noreferrer">
        <img
          src="https://images.ctfassets.net/f6kng81cu8b8/6k1HXkqD4kn33CCJshGbZv/12479acc0f674c957a8e975dbcf7fef2/appStore.svg"
          alt="Compensate on the App Store"
        />
      </AppLink>
    </Grid>
  </Grid>
);

const FAQs = () => (
  <Accordion
    size="small"
    items={[
      {
        key: "what",
        title: "What does carbon offsetting mean?",
        content:
          "Compensation (also known as carbon offsetting) is like a voluntary carbon tax that we pay to take responsibility for the choices we make as consumers. Carbon offsetting never justifies causing emissions – it is a way to compensate for emissions that cannot be avoided. Responsible carbon offsetting is based on the following principle: most importantly emissions must be avoided, then unavoidable emissions must be minimised, and finally the remaining emissions should be compensated.",
      },
      {
        key: "why",
        title: "Why is it worth compensating?",
        content:
          "Compensation is one climate tool among others. In order to limit global warming to 1.5°C as defined in the Paris Agreement, we need to both drastically reduce emissions and capture massive amounts of carbon dioxide from the atmosphere through compensation. Also, carbon offsetting makes the climate damage caused by consumerism visible by putting a price tag on carbon footprints, and hopefully guides our collective choices towards low emission alternatives.",
      },
      {
        key: "where",
        title: "Where do compensation payments go?",
        content:
          "When you compensate for your emissions through Compensate, the payment is allocated to purchasing certified carbon credits. We buy these credits from projects that have passed our strict evaluation criteria, which means they have a proven and quantifiable climate impact. Carbon credits prove that the amount of carbon dioxide equivalent to the emissions caused has been either removed from the atmosphere, or its leakage to the atmosphere has been prevented.",
      },
      {
        key: "how",
        title: "How do you verify the impact of carbon projects?",
        content:
          "Each project selected in our portfolio has passed a strict list of criteria that we have created together with a scientific panel consisting of top climate experts. There are thousands of carbon offsetting projects with international certifications around the world, however only less than 10% of them pass our criteria. Compensate’s strict evaluation process includes scoring projects in order to estimate the real climate impact of one carbon credit. This results in a project-specific climate impact score and we've incorporated overcompensation mechanism to ensure that one tonne compensated equals at least one less tonne of carbon dioxide in the atmosphere. We purchase carbon credits only directly from developers of verified, ongoing carbon capture projects to ensure their impact.",
      },
      { key: "extras", title: "What else can I do as a responsible consumer?", content: <WhatElseCanIDo /> },
    ]}
  />
);

const WhatIsThisModal = ({ customerName, onClose, isOpen }: Props) => (
  <StyledDialog open={isOpen} maxWidth="md" onClose={onClose} scroll="paper">
    <DialogHero>
      <CompensateLogo light />
      <StyledIconButton aria-label="close" onClick={onClose} color="inherit">
        <Close32 />
      </StyledIconButton>
    </DialogHero>

    <StyledDialogContent>
      <Typography variant="h3" gutterBottom>
        Compensate supports companies in taking responsibility for unavoidable emissions.
      </Typography>
      <Typography variant="h5" component="p" gutterBottom>
        This report shows the climate impact{" "}
        {!!customerName && (
          <>
            by <Highlight>{customerName}</Highlight>
          </>
        )}{" "}
        in collaboration with Compensate. Compensation, when done responsibly, is an important tool to reach climate
        goals, yet avoiding emissions is always a priority.
      </Typography>

      <FAQs />
    </StyledDialogContent>
  </StyledDialog>
);

export default WhatIsThisModal;
