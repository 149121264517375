import React, { useEffect, useState } from "react";
import { Box, Container, Fade, Grid, LinearProgress } from "@material-ui/core";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import { maptiler } from "pigeon-maps/providers";
import {
  Card,
  CardStat,
  createDataStore,
  fetchSelector,
  Footer,
  ImpactDistribution,
  ImpactEquivalentsCard,
  stateSelector,
  useFetchByDateRange,
  DatasetKey,
  SDG,
  Project,
  EmissionsOverview,
  MOBILE_SAFE_FRAME_MARGIN,
} from "@carbon/shared";

import { Impact as ImpactInterface } from "../types/impact.types";
import { createGetImpact } from "../api/impact.api";
import { Customer } from "../types/customer.types";
import { createGetCustomer } from "../api/customer.api";
import Hero from "../layout/Hero";
import WhatIsThisModal from "../components/WhatIsThisModal";
import SDGs from "../components/SDGs";

const MAPTILER_API_KEY = process.env.REACT_APP_MAPTILER_API_KEY || "";
const MAP_PROVIDER = maptiler(MAPTILER_API_KEY, "voyager");

if (!MAPTILER_API_KEY) {
  // eslint-disable-next-line no-console
  console.error("Missing maptiler API key, expected a value in REACT_APP_MAPTILER_API_KEY");
}

const publicId = window.location.pathname.replace(/^\//, "");
const useImpactStore = createDataStore<ImpactInterface>(createGetImpact(publicId));
const useCustomerStore = createDataStore<Customer>(createGetCustomer(publicId));

// The impact data shown in public impact reports, "amountSpent" is excluded
const SUPPORTED_PIE_DATASETS: DatasetKey[] = ["carbonCaptured", "creditsPurchased"];

const reduceSDGsFromProject = (sdgs: SDG[], project: Project): SDG[] => uniqBy([...sdgs, ...project.sdgs], "id");

const Impact = () => {
  const fetchImpact = useImpactStore(fetchSelector);
  const impactState = useImpactStore(stateSelector);

  const fetchCustomer = useCustomerStore(fetchSelector);
  const customerState = useCustomerStore(stateSelector);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useFetchByDateRange(fetchImpact);

  const impactLoading = impactState.status === "LOADING";
  const impactResolved = impactState.status === "RESOLVED";
  const customerResolved = customerState.status === "RESOLVED";
  const loading =
    impactLoading ||
    impactState.status === "IDLE" ||
    customerState.status === "LOADING" ||
    customerState.status === "IDLE";

  const hasNoImpact = impactResolved && impactState.data.carbonCaptured.amount === 0;
  const reportNotFound = impactState.status === "REJECTED" && impactState.error.status === 404;
  const supportedSDGs = impactResolved
    ? sortBy(impactState.data.projects.reduce(reduceSDGsFromProject, []), "number")
    : [];

  useEffect(() => {
    fetchCustomer();
  }, [fetchCustomer]);

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh" position="relative">
      <WhatIsThisModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        customerName={customerResolved ? customerState.data.name : undefined}
      />

      <Box position="absolute" top={0} left={0} right={0} zIndex={1}>
        <LinearProgress variant={loading ? "indeterminate" : "determinate"} value={loading ? 0 : 100} />
      </Box>

      <Hero
        onOpenHelpModal={() => setIsModalOpen(true)}
        companyLogo={customerResolved ? customerState.data.logo : undefined}
        companyName={customerResolved ? customerState.data.name : undefined}
        reportNotFound={reportNotFound}
        hideLogoBackground={customerResolved ? customerState.data.hide_logo_background : undefined}
      />

      {impactState.status !== "REJECTED" && (
        <Fade in={true}>
          <main>
            <Box maxWidth={1400} mx="auto" my={[2, 3]}>
              <Container maxWidth={false}>
                <Grid container spacing={4} alignItems="stretch">
                  <Card width="half" title="Carbon captured" loading={impactLoading}>
                    <CardStat>
                      {impactResolved &&
                        impactState.data.carbonCaptured.fromGrams().toString({ equivalent: false, decimals: 1 })}
                    </CardStat>
                  </Card>

                  <Fade
                    in={impactResolved && impactState.data.emissionsCompensated.amount > 0}
                    timeout={{ enter: 600 }}
                    unmountOnExit
                  >
                    <Card width="half" title="Emissions compensated" loading={false}>
                      <CardStat>
                        {impactResolved &&
                          impactState.data.emissionsCompensated
                            .fromGrams()
                            .toString({ equivalent: false, decimals: 1 })}
                      </CardStat>
                    </Card>
                  </Fade>
                </Grid>
              </Container>
            </Box>

            {!hasNoImpact && (
              <>
                <Box maxWidth={1400} mx="auto" my={[2, 3]}>
                  <ImpactEquivalentsCard
                    loading={impactLoading}
                    carbonCaptured={impactResolved ? impactState.data.carbonCaptured : undefined}
                  />
                </Box>

                <Box bgcolor={loading ? "gray.light" : "beige.main"}>
                  <Box maxWidth={1400} mx="auto">
                    <EmissionsOverview
                      loading={impactLoading}
                      emissionCategories={impactResolved ? impactState.data.emissionCategories : undefined}
                      emissionSources={impactResolved ? impactState.data.emissionSources : undefined}
                    />
                  </Box>
                </Box>

                <Box bgcolor={loading ? "gray.light" : "primary.extraLight"}>
                  <Box maxWidth={1400} mx="auto">
                    <Container maxWidth={false}>
                      <ImpactDistribution
                        loading={impactLoading}
                        datasets={SUPPORTED_PIE_DATASETS}
                        impactDistribution={impactResolved ? impactState.data.impactDistribution : undefined}
                        projectDistribution={impactResolved ? impactState.data.impactDistribution.byProject : undefined}
                        projects={impactResolved ? impactState.data.projects : undefined}
                        provider={MAP_PROVIDER}
                      />
                    </Container>
                  </Box>
                </Box>

                <Container maxWidth="lg">
                  <Box my={MOBILE_SAFE_FRAME_MARGIN}>
                    <SDGs sdgs={supportedSDGs} />
                  </Box>
                </Container>
              </>
            )}
          </main>
        </Fade>
      )}

      <Footer
        withFAQs={false}
        productLinks={[]}
        tagline="Compensate combats climate change by offering everyone easy access to high-quality carbon projects"
      />
    </Box>
  );
};

export default Impact;
