/**
 * Utilities to handle different states when fetching data. ...TODO
 */

import create, { State } from "zustand";
import formatISO from "date-fns/formatISO";

import { ApiError } from "../utils/errors";
import { DateRange } from "../constants";

export type Status = "IDLE" | "LOADING" | "RESOLVED" | "REJECTED";

interface Base {
  status: Status;
}

interface CurrentStatus {
  isIdle: boolean;
  isLoading: boolean;
  isRejected: boolean;
  isResolved: boolean;
}

export class Idle implements Base {
  status: "IDLE" = "IDLE";
}

export class Loading implements Base {
  status: "LOADING" = "LOADING";
}

export class Resolved<T> implements Base {
  status: "RESOLVED" = "RESOLVED";

  constructor(public data: T) {}
}

export class Rejected implements Base {
  status: "REJECTED" = "REJECTED";

  constructor(public error: ApiError) {}
}

// Possible states when fetching data from an external service
export type RequestState<T> = Idle | Loading | Resolved<T> | Rejected;

interface StoreState<T> extends State {
  state: Idle | Loading | Resolved<T> | Rejected;
  fetch: (dateRange?: DateRange) => void;
  getStatus: () => CurrentStatus;
}

/** State selectors to avoid creating a new function on each render **/

export function stateSelector<T>(store: StoreState<T>) {
  return store.state;
}

export function fetchSelector<T>(state: StoreState<T>) {
  return state.fetch;
}

export function getStatusSelector<T>(state: StoreState<T>) {
  return state.getStatus;
}

function getDateParams({ start, end }: DateRange): URLSearchParams | undefined {
  const searchParams = new URLSearchParams();

  if (!start && !end) {
    return undefined;
  }

  if (start) {
    searchParams.append("start_date", formatISO(start, { representation: "date" }));
  }

  if (end) {
    searchParams.append("end_date", formatISO(end, { representation: "date" }));
  }

  return searchParams;
}

/**
 * Create a data store to handle storing data fetched from an API consistently.
 * Once created, the store stores data, errors and a status to be consumed by the view layer.
 *
 * A generic is used to determine the type of data being fetched, for example
 * createDataStore<Calculations>(getCalculations) creates a store which fetches calculations.
 *
 * @param fetchHandler The function that fetches data from an API
 */
export function createDataStore<T>(fetchHandler: (urlParams?: URLSearchParams) => Promise<T>) {
  return create<StoreState<T>>((set, get) => ({
    state: new Idle(),

    /**
     * Get shorthand booleans for conditional rendering, note that these can not be used as type guards
     */
    getStatus() {
      const state = get().state;

      return {
        isIdle: state.status === "IDLE",
        isLoading: state.status === "LOADING",
        isRejected: state.status === "REJECTED",
        isResolved: state.status === "RESOLVED",
      };
    },

    async fetch(dateRange?: DateRange) {
      const dateParams = dateRange ? getDateParams(dateRange) : undefined;

      set({ state: new Loading() });

      try {
        const data = await fetchHandler(dateParams);

        set({ state: new Resolved(data) });
      } catch (error: unknown) {
        set({ state: new Rejected(error as ApiError) });
      }
    },
  }));
}
