import { executeApiQuery } from "@carbon/shared";
import type { Request } from "@carbon/shared/src/utils/api";

export function apiQuery<T>(apiRequest: Request<T>) {
  return {
    apiRequest,
    run() {
      return executeApiQuery(apiRequest);
    },
  };
}
