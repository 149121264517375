/**
 * Helper for consistently formatting dates
 * The date argument must by either a Date or ISO-8601 string
 */
class DateFormatter {
  public date: Date;
  private readonly defaultOptions: Intl.DateTimeFormatOptions = {
    dateStyle: "short",
    timeStyle: "short",
  };

  constructor(date: Date | string, private locale?: string) {
    this.date = typeof date === "string" ? new Date(date) : date;
  }

  toString(options?: Intl.DateTimeFormatOptions): string {
    return new Intl.DateTimeFormat(this.locale, options || this.defaultOptions).format(this.date);
  }
}

export default DateFormatter;
