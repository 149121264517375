import React, { useEffect, useState } from "react";
import { ResponsivePie, DefaultRawDatum, ComputedDatum } from "@nivo/pie";
import { Grid, Box, Typography, Chip } from "@material-ui/core";
import orderBy from "lodash/orderBy";
import styled from "styled-components";
import { Carbon20, CropGrowth20, PiggyBankSlot20 } from "@carbon/icons-react";

import { Emissions, Money } from "../utils";
import { useSmoothScrollToHash } from "../hooks";
import { spacing } from "../theme";
import { ImpactOverviewByType, ImpactOverviewByProject, ImpactOverview, Project } from "../types";
import getProjectTypeDetails, { getProjectColorShade } from "../utils/get-project-type";
import useProjectMapStore, { handleSelectProjectSelector } from "../store/project-map.store";

import Card from "./Card";
import PieTooltip from "./PieTooltip";
import ProjectMap from "./ProjectMap";

interface ProjectTypeDatum {
  label: string;
  description: string;
  color: string;
  carbonCaptured: number;
  creditsPurchased: number;
  amountSpent?: number;
}

interface ProjectDatum {
  label: string;
  color: string;
  id: string;
  carbonCaptured: number;
  creditsPurchased: number;
  amountSpent?: number;
}

const COMMON_PIE_PROPS = {
  arcLinkLabel: trimPieSegmentLabel,
  margin: { top: 40, bottom: 40, left: 40, right: 40 },
  innerRadius: 0.7,
  activeOuterRadiusOffset: 8,
  colors: { datum: "data.color" },
  borderWidth: 0,
  arcLinkLabelsSkipAngle: 8,
  arcLinkLabelsOffset: 2,
  arcLinkLabelsTextOffset: 2,
  arcLinkLabelsTextColor: "#000000",
  arcLinkLabelsDiagonalLength: 14,
  arcLinkLabelsStraightLength: 11,
  arcLinkLabelsThickness: 2,
  arcLinkLabelsColor: "#000000",
  enableArcLabels: false,
  id: "label",
};

function getColorIndex(currProject: ImpactOverviewByProject, projects: ImpactOverviewByProject[]): number {
  const projectsWithType = projects.filter((project) => project.type === currProject.type);

  return projectsWithType?.indexOf(currProject) || 0;
}

function createOrderProjectBy(datasetKey: DatasetKey) {
  // Order project by amount of euros spent, carbon captured or credits purchased
  return function (impactOverview: ImpactOverviewByProject): number {
    const value = impactOverview[datasetKey];

    if (value instanceof Emissions || value instanceof Money) {
      return value.amount;
    }

    return value || 0;
  };
}

/**
 * Order projects by type and then amount (from highest to smallest)
 */
function getProjectPieData(distribution: Distribution, datasetConfig: DatasetOption): ProjectDatum[] {
  return orderBy(distribution.byProject, ["type", createOrderProjectBy(datasetConfig.key)], ["asc", "desc"]).map(
    (project, i, sortedProjects) => {
      const colorIndex = getColorIndex(project, sortedProjects);
      const { amountSpent } = project;
      const withAmountSpent = amountSpent instanceof Money;

      return {
        id: project.id,
        label: project.name,
        color: project.type ? getProjectColorShade(project.type, colorIndex) : "#000",

        /**
         * Convert Emissions and Money back to numbers to allow nivo to calculate the pie segments
         */
        creditsPurchased: project.creditsPurchased,
        carbonCaptured: project.carbonCaptured.amount,
        ...(withAmountSpent
          ? {
              amountSpent: amountSpent.amount,
            }
          : {}),
      };
    },
  );
}

function getProjectTypePieData(distribution: Distribution): ProjectTypeDatum[] {
  return orderBy(distribution.byProjectType, "type").map((distribution) => {
    const projectTypeDetails = getProjectTypeDetails(distribution.type);
    const { amountSpent } = distribution;
    const withAmountSpent = amountSpent instanceof Money;

    return {
      label: projectTypeDetails.title,
      description: projectTypeDetails.description,
      color: getProjectColorShade(distribution.type),

      /**
       * Convert Emissions and Money back to numbers to allow nivo to calculate the pie segments
       */
      creditsPurchased: distribution.creditsPurchased,
      carbonCaptured: distribution.carbonCaptured.amount,
      ...(withAmountSpent
        ? {
            amountSpent: amountSpent.amount,
          }
        : {}),
    };
  });
}

/**
 * Nivo doesn't wrap labels, so cap pie labels to 16 characters
 * and indicate an ellipsis to avoid cutting off labels
 */
function trimPieSegmentLabel({ id }: DefaultRawDatum): string {
  if (typeof id === "number") {
    return id.toString();
  }

  if (id.length > 16) {
    return `${id.substring(0, 16).trim()}...`;
  }

  return id;
}

const Subtitle = styled(Typography)`
  margin-top: ${spacing(2)}px;
`;

const ProjectPieBox = styled(Box)`
  path {
    cursor: pointer;
  }
`;

const BlockCard = styled(Card)`
  border-radius: 0;
  padding-top: ${spacing(4)}px;
  padding-bottom: ${spacing(4)}px;
`;

const CenteredCard = styled(Card)`
  text-align: center;
  padding-top: ${spacing(2)}px;
  padding-bottom: ${spacing(2)}px;
`;

const StyledGrid = styled(Grid)`
  padding-top: ${spacing(2)}px;
`;

export type DatasetKey = keyof ImpactOverview;

interface DatasetOption {
  key: DatasetKey;
  label: string;
  icon: React.ReactElement;
  valueFormat?: (value: number) => string;
}

const DATASET_OPTIONS: DatasetOption[] = [
  {
    key: "carbonCaptured",
    label: "Carbon captured",
    icon: <CropGrowth20 />,
    valueFormat: (value) => new Emissions(value).fromGrams().toString({ equivalent: false }),
  },
  {
    key: "creditsPurchased",
    label: "Credits retired",
    icon: <Carbon20 />,
  },
  {
    key: "amountSpent",
    label: "Euros invested",
    icon: <PiggyBankSlot20 />,
    valueFormat: (value) => new Money(value).fromSubunit().toString(),
  },
];

// Flat list of DATASET_OPTIONS keys
const DEFAULT_SUPPORTED_DATASETS = DATASET_OPTIONS.map((option) => option.key);

interface Distribution {
  byProject: ImpactOverviewByProject[];
  byProjectType: ImpactOverviewByType[];
}

interface Props {
  loading: boolean;
  impactDistribution?: Distribution;
  /** A list of dataset keys which should be supported by the pie chart e.g. ["carbonCaptured", "creditsPurchased"] */
  datasets?: DatasetKey[];
  projects?: Project[];
  projectDistribution?: ImpactOverviewByProject[];
  provider: (x: number, y: number, z: number, dpr?: number) => string;
}

const ImpactDistribution = ({
  loading,
  impactDistribution,
  datasets = DEFAULT_SUPPORTED_DATASETS,
  projects,
  projectDistribution,
  provider,
}: Props) => {
  const handleSelectProject = useProjectMapStore(handleSelectProjectSelector);
  const scrollTo = useSmoothScrollToHash();

  const [datasetConfig, setDatasetConfig] = useState<DatasetOption>(DATASET_OPTIONS[0]);
  const [datasetOptions, setDatasetOptions] = useState<DatasetOption[]>(DATASET_OPTIONS);

  useEffect(() => {
    if (impactDistribution) {
      setDatasetOptions(DATASET_OPTIONS.filter((option) => datasets.includes(option.key)));
    }
  }, [impactDistribution, datasets]);

  function handleClickProject(datum: ComputedDatum<ProjectDatum>) {
    handleSelectProject(datum.data.id);
    scrollTo("portfolio-map");
  }

  return (
    <BlockCard
      width="full"
      title="Carbon capture distribution"
      loading={loading}
      actions={
        !!impactDistribution && (
          <Grid container spacing={1} alignItems="center">
            {datasetOptions.map((option) => (
              <Grid item key={option.key}>
                <Chip
                  color="primary"
                  variant={datasetConfig.key === option.key ? "default" : "outlined"}
                  label={option.label}
                  icon={option.icon}
                  onClick={() => setDatasetConfig(option)}
                />
              </Grid>
            ))}
          </Grid>
        )
      }
    >
      <StyledGrid container spacing={4}>
        <Grid item xs={12} md={6}>
          <CenteredCard loading={loading} bgcolor="beige.extraLight">
            <Subtitle variant="h6">Investment by project type</Subtitle>
            <Box height={250}>
              {!!impactDistribution && (
                <ResponsivePie<ProjectTypeDatum>
                  {...COMMON_PIE_PROPS}
                  value={datasetConfig.key}
                  data={getProjectTypePieData(impactDistribution)}
                  tooltip={(tooltip) => (
                    <PieTooltip
                      color={tooltip.datum.color}
                      title={tooltip.datum.data.label}
                      description={tooltip.datum.data.description}
                      formattedValue={tooltip.datum.formattedValue}
                    />
                  )}
                  valueFormat={datasetConfig.valueFormat}
                />
              )}
            </Box>
          </CenteredCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <CenteredCard loading={loading} bgcolor="beige.extraLight">
            <Subtitle variant="h6">Investment by project</Subtitle>
            <ProjectPieBox height={250}>
              {!!impactDistribution && (
                <ResponsivePie<ProjectDatum>
                  {...COMMON_PIE_PROPS}
                  value={datasetConfig.key}
                  data={getProjectPieData(impactDistribution, datasetConfig)}
                  tooltip={(tooltip) => (
                    <PieTooltip
                      color={tooltip.datum.color}
                      title={tooltip.datum.data.label}
                      formattedValue={tooltip.datum.formattedValue}
                    />
                  )}
                  valueFormat={datasetConfig.valueFormat}
                  onClick={handleClickProject}
                />
              )}
            </ProjectPieBox>
          </CenteredCard>
        </Grid>
      </StyledGrid>

      <ProjectMap projectDistribution={projectDistribution} projects={projects} provider={provider} />
    </BlockCard>
  );
};

export default ImpactDistribution;
