import React from "react";
import { Box, Grid, Tooltip, Typography } from "@material-ui/core";
import styled from "styled-components";
import { SDG } from "@carbon/shared";
import { Information16 } from "@carbon/icons-react";

interface Props {
  sdgs: SDG[];
}

const SDGContainer = styled(Grid)`
  font-size: 0;
`;

const SDGImage = styled.img`
  width: 100%;
  height: auto;
`;

const SDGCount = styled((props) => <Typography variant="h2" component="span" {...props} />)`
  margin-right: 12px;
`;

const SDGs = ({ sdgs }: Props) => {
  if (!sdgs.length) {
    return null;
  }

  // When on one row, align the SDG boxes center so they're in line with the title
  const justifySDGs = sdgs.length > 6 ? "flex-start" : "center";

  return (
    <Box width="100%">
      <Box display="flex" justifyContent="center" alignItems="center" component="h2">
        <SDGCount>{sdgs.length}</SDGCount>
        <Typography component="span">
          <b>Sustainable Development Goals</b>{" "}
          <Tooltip
            placement="top"
            arrow
            title={
              <>
                <Typography variant="caption" paragraph>
                  The Sustainable Development Goals (SDGs) were adopted by the United Nations in 2015 as a universal
                  call to action to end poverty, protect the planet, and ensure that by 2030 all people enjoy peace and
                  prosperity.
                </Typography>

                <Typography variant="caption">
                  Most of the carbon projects in Compensate's portfolio support a vast majority of the UN's Sustainable
                  Development goals.
                </Typography>
              </>
            }
          >
            <Information16 />
          </Tooltip>
          <br />
          are supported by this compensation
        </Typography>
      </Box>

      <Box maxWidth={860} mx="auto">
        <Grid container spacing={1} justifyContent={justifySDGs}>
          {sdgs.map(({ image, title }, i) => (
            <SDGContainer key={i} item xs={3} sm={2} md={2}>
              <SDGImage src={image.url} alt={title} />
            </SDGContainer>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default SDGs;
