import { apiQuery } from "./api";

const timeout = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Create the GET customer handler, with the public ID of the customer (located in the URL)
 *
 * Wait for a minimum of 1 second before resolving. If the customer request took longer than
 * a second, no extra wait time is enforced. This is to ensure a smooth transition between
 * loading and resolved states.
 */
export const createGetCustomer = (publicId: string) => async () => {
  const time = Date.now();
  const customer = await apiQuery({ type: "GET", path: `/v1/customer/${publicId}` }).run();
  const timeWaited = Date.now() - time;

  await timeout(Math.max(0, 1000 - timeWaited));

  return customer;
};
