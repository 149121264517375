import { Emissions } from "@carbon/shared";
import round from "lodash/round";
import { Impact, ImpactOverview, ImpactOverviewResponse, ImpactResponse } from "../types/impact.types";
import { apiQuery } from "./api";

const mapImpactOverviewResponse = (overview: ImpactOverviewResponse): ImpactOverview => ({
  creditsPurchased: round(overview.credits_purchased, 2),
  carbonCaptured: new Emissions(overview.carbon_captured),
});

const mapImpactResponse = (impactResponse: ImpactResponse): Impact => ({
  ...impactResponse,
  carbonCaptured: new Emissions(impactResponse.carbon_captured),
  emissionsCompensated: new Emissions(impactResponse.emissions_compensated),
  impactDistribution: {
    // Map project distribution to include name and type for pie chart visualisation
    byProject: impactResponse.impact_distribution.by_project.map((distribution) => {
      const project = impactResponse.projects.find((project) => project.id === distribution.id);

      return {
        id: distribution.id,
        name: project?.name ?? distribution.id,
        type: project?.type,
        ...mapImpactOverviewResponse(distribution),
      };
    }),
    byProjectType: impactResponse.impact_distribution.by_project_type.map((distribution) => ({
      type: distribution.type,
      ...mapImpactOverviewResponse(distribution),
    })),
  },
  emissionCategories: impactResponse.emission_categories,
  emissionSources: impactResponse.emission_sources.map((source) => ({
    category: source.category,
    emissions: new Emissions(source.emissions),
  })),
});

export const createGetImpact = (publicId: string) => async (urlParams?: URLSearchParams): Promise<Impact> => {
  const hasParams = !!urlParams?.toString();
  const basePath = `/v1/impact/${publicId}`;
  const path = hasParams ? `${basePath}?${urlParams!.toString()}` : basePath;

  const impactResponse = await apiQuery({ type: "GET", path }).run();

  return mapImpactResponse(impactResponse);
};
