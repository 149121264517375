import React from "react";
import { Grid, Box, Paper, Typography } from "@material-ui/core";

interface Props {
  color: string;
  description?: string;
  formattedValue: string;
  title: string;
}

const PieTooltip = ({ color, description, formattedValue, title }: Props) => (
  <Paper>
    <Box p={1} maxWidth={420}>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Box bgcolor={color} width={16} height={16} />
        </Grid>
        <Grid item>
          <Typography variant="body2">{title}:</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" component="p">
            {formattedValue}
          </Typography>
        </Grid>
      </Grid>
      {description && <Typography variant="body2">{description}</Typography>}
    </Box>
  </Paper>
);

export default PieTooltip;
