import React from "react";
import { Box, BoxProps } from "@material-ui/core";

interface IconCardProps extends BoxProps {
  icon: React.ReactNode;
  children: React.ReactNode;
}

const IconCard = ({ icon, children, ...props }: IconCardProps) => (
  <Box display="flex" alignItems="center" borderRadius={4} px={2} py={1} bgcolor="rgba(0, 0, 0, 0.1)" {...props}>
    {icon}
    <Box ml={2}>{children}</Box>
  </Box>
);

export default IconCard;
