import React, { Fragment } from "react";
import { ResponsivePie, DefaultRawDatum } from "@nivo/pie";
import {
  Grid,
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Tooltip,
  ListSubheader,
} from "@material-ui/core";
import styled from "styled-components";
import { Information16 } from "@carbon/icons-react";

import { Emissions } from "../utils";
import { spacing } from "../theme";

import Card from "./Card";
import PieTooltip from "./PieTooltip";
import {
  EmissionCategory,
  EmissionSource,
  getEmissionsBySourcePieData,
  getEmissionsByGHGScopePieData,
  EmissionSourceDatum,
  groupOthers,
  EmissionCategoryDatum,
} from "../utils/emissions-overview";
import palette from "../theme/palette";

const COMMON_PIE_PROPS = {
  arcLinkLabel: trimPieSegmentLabel,
  margin: { top: 40, bottom: 40, left: 40, right: 40 },
  innerRadius: 0.7,
  activeOuterRadiusOffset: 8,
  colors: { datum: "data.color" },
  borderWidth: 0,
  arcLinkLabelsSkipAngle: 8,
  arcLinkLabelsOffset: 2,
  arcLinkLabelsTextOffset: 2,
  arcLinkLabelsTextColor: "#000000",
  arcLinkLabelsDiagonalLength: 14,
  arcLinkLabelsStraightLength: 11,
  arcLinkLabelsThickness: 2,
  arcLinkLabelsColor: "#000000",
  enableArcLabels: false,
  id: "name",
};

/**
 * Nivo doesn't wrap labels, so cap pie labels to 16 characters
 * and indicate an ellipsis to avoid cutting off labels
 */
function trimPieSegmentLabel({ id }: DefaultRawDatum): string {
  if (typeof id === "number") {
    return id.toString();
  }

  if (id.length > 16) {
    return `${id.substring(0, 16).trim()}...`;
  }

  return id;
}

const Subtitle = styled(Typography)`
  margin-top: ${spacing(2)}px;
`;

const BlockCard = styled(Card)`
  border-radius: 0;
  padding-top: ${spacing(2)}px;
  padding-bottom: ${spacing(2)}px;
`;

const CenteredCard = styled(Card)`
  text-align: center;
  padding-top: ${spacing(2)}px;
  padding-bottom: ${spacing(2)}px;
`;

const Circle = styled(Box)<{ color: string }>`
  width: ${spacing(1.5)}px;
  height: ${spacing(1.5)}px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: unset;
  margin-right: ${spacing(1)}px;
`;

const ThinDivider = styled(Divider)`
  height: 1px;
`;

const StyledGrid = styled(Grid)`
  padding-top: ${spacing(4)}px;
  padding-bottom: ${spacing(4)}px;
`;

const InlineListItemText = styled(ListItemText)`
  display: flex;
  align-items: center;
  padding-right: ${spacing(2)}px;
`;

const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
`;

const FlexGrid = styled(Grid)`
  display: flex;
`;

const StyledListSubheader = styled(ListSubheader)`
  text-align: right;
  line-height: initial;
  margin-top: ${spacing(4)}px;
`;

const StyledListItemText = styled(ListItemText)`
  padding-right: ${spacing(2)}px;
`;

const ListHeader = styled(Typography)`
  color: ${palette.gray[60]};
  margin-right: ${spacing(2)}px;
`;

interface Props {
  loading: boolean;
  emissionCategories?: EmissionCategory[];
  emissionSources?: EmissionSource[];
}

const EmissionsOverview = ({ loading, emissionCategories, emissionSources }: Props) => {
  const emissionsBySourcePieData = getEmissionsBySourcePieData(emissionSources, emissionCategories);
  const groupedEmissionsBySourcePieData = groupOthers(emissionsBySourcePieData);
  const emissionsByGHGScopePieData = getEmissionsByGHGScopePieData(emissionSources, emissionCategories);

  return (
    <>
      {emissionsBySourcePieData.length > 0 && (
        <BlockCard width="full" title="Emissions overview" loading={loading} bgcolor="beige.main">
          <StyledGrid container spacing={4}>
            <FlexGrid item xs={12} md={6}>
              <CenteredCard loading={loading} bgcolor="beige.extraLight">
                <Subtitle variant="h6">Emissions by source</Subtitle>
                <Box height={250}>
                  <ResponsivePie<EmissionSourceDatum>
                    {...COMMON_PIE_PROPS}
                    value="emissions"
                    data={groupedEmissionsBySourcePieData}
                    valueFormat={(value) => new Emissions(value).fromGrams().toString({ equivalent: false })}
                    tooltip={(tooltip) => (
                      <PieTooltip
                        color={tooltip.datum.color}
                        title={tooltip.datum.data.name}
                        formattedValue={tooltip.datum.formattedValue}
                      />
                    )}
                  />
                </Box>
                <List
                  subheader={
                    <StyledListSubheader disableSticky disableGutters>
                      <ListHeader variant="caption">
                        Emissions (tCO<sub>2</sub>e)
                      </ListHeader>
                    </StyledListSubheader>
                  }
                >
                  {emissionsBySourcePieData.map((data, index) => (
                    <Fragment key={`${data.id}${index}`}>
                      <ListItem dense>
                        <StyledListItemIcon>
                          <Circle color={data.color} />
                        </StyledListItemIcon>
                        <InlineListItemText
                          primary={data.name}
                          secondary={
                            <Typography component="span">
                              {data.description && (
                                <Tooltip
                                  placement="top"
                                  arrow
                                  title={
                                    <Typography variant="caption" paragraph>
                                      {data.description}
                                    </Typography>
                                  }
                                >
                                  <FlexBox mx={1}>
                                    <Information16 />
                                  </FlexBox>
                                </Tooltip>
                              )}
                            </Typography>
                          }
                        />
                        <ListItemSecondaryAction>
                          <ListItemText
                            primary={new Emissions(data.emissions).fromGrams().toString({ withUnit: false })}
                            primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                      {emissionsBySourcePieData.length - 1 !== index && <ThinDivider variant="middle" />}
                    </Fragment>
                  ))}
                </List>
              </CenteredCard>
            </FlexGrid>
            <FlexGrid item xs={12} md={6}>
              <CenteredCard loading={loading} bgcolor="beige.extraLight">
                <Subtitle variant="h6">Emissions by GHG scope</Subtitle>
                <Box height={250}>
                  <ResponsivePie<EmissionCategoryDatum>
                    {...COMMON_PIE_PROPS}
                    data={emissionsByGHGScopePieData}
                    value="emissions"
                    valueFormat={(value) => new Emissions(value).fromGrams().toString({ equivalent: false })}
                    tooltip={(tooltip) => (
                      <PieTooltip
                        color={tooltip.datum.color}
                        title={tooltip.datum.data.name}
                        formattedValue={tooltip.datum.formattedValue}
                      />
                    )}
                  />
                </Box>
                <List
                  subheader={
                    <StyledListSubheader disableSticky disableGutters>
                      <ListHeader variant="caption">
                        Emissions (tCO<sub>2</sub>e)
                      </ListHeader>
                    </StyledListSubheader>
                  }
                >
                  {emissionsByGHGScopePieData.map((data, index) => (
                    <Fragment key={`${data.scope}${index}`}>
                      <ListItem dense>
                        <StyledListItemIcon>
                          <Circle color={data.color} />
                        </StyledListItemIcon>
                        <StyledListItemText primary={data.longName} />
                        <ListItemSecondaryAction>
                          <ListItemText
                            primary={new Emissions(data.emissions).fromGrams().toString({ withUnit: false })}
                            primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                      {emissionsByGHGScopePieData.length - 1 !== index && <ThinDivider variant="middle" />}
                    </Fragment>
                  ))}
                </List>
              </CenteredCard>
            </FlexGrid>
          </StyledGrid>
        </BlockCard>
      )}
    </>
  );
};

export default EmissionsOverview;
