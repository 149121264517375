import { useEffect } from "react";

import useDateRangePicker, { dateRangeSelector } from "../store/date-range-picker";
import { DateRange } from "../constants";

/**
 * Calls fetchHandler every time date range picker state changes.
 * To avoid fetching on each render, fetchHandler must be memoized
 * or created outside of the component calling this hook.
 *
 * This doesn't return the data from fetchHandler, so it's expected that
 * fetchHandler sets data to state following the approach of utils/data-store.ts
 */
const useFetchByDateRange = (fetchHandler: (dateRange: DateRange) => void) => {
  const dateRange = useDateRangePicker(dateRangeSelector);

  useEffect(() => {
    fetchHandler(dateRange);
  }, [fetchHandler, dateRange]);
};

export default useFetchByDateRange;
