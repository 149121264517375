import React from "react";
import { Grid, Typography } from "@material-ui/core";
import styled from "styled-components";
import { Car32, Tree32 } from "@carbon/icons-react";

import { palette, spacing } from "../theme";
import { Emissions } from "../utils";
import Card from "./Card";

const BeigeCard = styled(Card)`
  text-align: left;
  background-color: ${palette("beige.transparent")};
`;

const EquivalentStat = styled((props) => <Typography variant="h4" component="span" {...props} />)`
  display: block;
`;

const CenteredCard = styled(Card)`
  text-align: center;
  padding-top: ${spacing(4)}px;
  padding-bottom: ${spacing(4)}px;
`;

interface EquivalentsCardProps {
  icon: React.ReactNode;
  children: React.ReactNode;
}

const EquivalentsCard = ({ icon, children }: EquivalentsCardProps) => (
  <BeigeCard width="half">
    {icon}
    <Typography variant="h6" component="p">
      {children}
    </Typography>
  </BeigeCard>
);

interface Props {
  loading: boolean;
  carbonCaptured?: Emissions;
}

const ImpactEquivalentsCard = ({ loading, carbonCaptured }: Props) => {
  return (
    <CenteredCard width="full" loading={loading} bgcolor="beige.extraLight">
      {!!carbonCaptured && (
        <>
          <Typography variant="h5" component="h2" paragraph>
            {carbonCaptured.fromGrams().toString({ equivalent: false, decimals: 1 })} is equal to the impact of
          </Typography>

          <Grid container spacing={3}>
            <EquivalentsCard icon={<Tree32 />}>
              Protecting{" "}
              <EquivalentStat>{carbonCaptured.toImpactEquivalent("squareMetersRainforestProtected")}</EquivalentStat>{" "}
              square meters of rainforest
            </EquivalentsCard>

            <EquivalentsCard icon={<Car32 />}>
              Driving
              <EquivalentStat>{carbonCaptured.toImpactEquivalent("kmDriven")}</EquivalentStat>
              kilometers
            </EquivalentsCard>
          </Grid>
        </>
      )}
    </CenteredCard>
  );
};

export default ImpactEquivalentsCard;
