import config from "react-global-configuration";

const DEVELOPMENT = {
  env: "development",
  analytics: false,
  features: {},
};

const LOCAL = {
  ...DEVELOPMENT,
  analytics: false,
};

const PRODUCTION = {
  env: "production",
  analytics: true,
  features: {},
};

switch (window.location.hostname) {
  case "localhost":
    config.set(LOCAL);
    break;
  case "impact-dev.compensate.com":
    config.set(DEVELOPMENT);
    break;
  case "impact.compensate.com":
  default:
    config.set(PRODUCTION);
    break;
}

export default config;
