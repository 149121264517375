import React from "react";
import ReactDOM from "react-dom";
import { FeatureProvider, ThemeProviders } from "@carbon/shared";

import App from "./App";
import config from "./config";

ReactDOM.render(
  <ThemeProviders>
    <FeatureProvider features={config.get("features")}>
      <App />
    </FeatureProvider>
  </ThemeProviders>,
  document.getElementById("root"),
);
