import React, { useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import { Map, Marker, Overlay } from "pigeon-maps";
import styled from "styled-components";

import { ImpactOverviewByProject, Project } from "../types";
import useProjectMapStore, {
  selectedProjectSelector,
  clearSelectedProjectSelector,
  handleSelectProjectSelector,
} from "../store/project-map.store";
import { getProjectColorShade } from "../utils/get-project-type";
import ProjectDrawer from "./ProjectDrawer";

const StyledMarker = styled(Marker)`
  &.project-selected {
    filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 0px 4px);

    path {
      filter: brightness(120%);
    }

    circle {
      opacity: 1;
    }
  }
`;

const renderMarkers = (projects: Project[], onSelectProject: (projectId: string) => void, selectedProjectId?: string) =>
  projects.map((project) => {
    if (project.location) {
      const isSelected = project.id === selectedProjectId;

      return (
        <StyledMarker
          key={project.id}
          width={40}
          anchor={[project.location.lat, project.location.lon]}
          onClick={() => onSelectProject(project.id)}
          color={getProjectColorShade(project.type)}
          className={isSelected ? "project-selected" : undefined}
        />
      );
    }

    return null;
  });

const ProjectLabel = styled((props) => <Typography variant="body2" {...props} />)`
  width: 200px;
  text-align: center;
  font-weight: bold;
  text-shadow: -1px -1px #fff, 1px -1px #fff, -1px 1px #fff, 1px 1px #fff;
  line-height: 1;
`;

const renderLabels = (projects: Project[]) =>
  projects.map((project) => {
    if (project.location) {
      return (
        <Overlay key={project.id} anchor={[project.location.lat, project.location.lon]} offset={[100, 5]}>
          <ProjectLabel>{project.name}</ProjectLabel>
        </Overlay>
      );
    }
    return null;
  });

function getProjectDistribution(projectId: string, projectDistribution?: ImpactOverviewByProject[]) {
  const projectImpact = projectDistribution?.find((project) => project.id === projectId);

  if (!projectImpact) {
    return undefined;
  }

  return projectImpact;
}

interface Props {
  projects?: Project[];
  projectDistribution?: ImpactOverviewByProject[];
  provider: (x: number, y: number, z: number, dpr?: number) => string;
}

const ProjectMap = ({ projects, projectDistribution, provider }: Props) => {
  const selectedProjectId = useProjectMapStore(selectedProjectSelector);
  const clearSelectedProject = useProjectMapStore(clearSelectedProjectSelector);
  const handleSelectProject = useProjectMapStore(handleSelectProjectSelector);

  const selectedProject = !!projects ? projects.find((project) => project.id === selectedProjectId) : undefined;

  useEffect(() => {
    if (!!projects && selectedProjectId && !selectedProject) {
      // Clear the selected project if it wasn't found in the impact response, for example,
      // if the user changes the date range filter and the selected project is no longer returned.
      clearSelectedProject();
    }
  }, [projects, clearSelectedProject, selectedProjectId, selectedProject]);

  return (
    <Box id="portfolio-map" height={500} mt={2} position="relative" overflow="hidden">
      {!!projects && (
        <>
          <Map height={500} defaultCenter={[0, 0]} defaultZoom={2.5} minZoom={2} maxZoom={4} provider={provider}>
            {renderLabels(projects)}
            {renderMarkers(projects, handleSelectProject, selectedProjectId)}
          </Map>

          {!!selectedProject && (
            <ProjectDrawer
              visible={!!selectedProject}
              project={selectedProject}
              onClose={clearSelectedProject}
              impact={getProjectDistribution(selectedProject.id, projectDistribution)}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default ProjectMap;
